
import { defineComponent } from 'vue'

export default defineComponent({
  setup: () => {
    return {
      links: [
        { name: 'home', url: '/', title: 'Home' },
        { name: 'about', url: '/about', title: 'About' },
        { name: 'projects', url: '/projects', title: 'Projects' },
        { name: 'cv', url: '/cv', title: 'CV' },
        { name: 'projects', url: '/projects', title: 'Projects' },
      ],
    }
  },
})
