import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22138da2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cv_section_loader = _resolveComponent("cv-section-loader")!
  const _component_the_projects_modal_content = _resolveComponent("the-projects-modal-content")!
  const _component_modal_tech_stack = _resolveComponent("modal-tech-stack")!
  const _component_modal_pager = _resolveComponent("modal-pager")!
  const _component_modal_nav_close_btn = _resolveComponent("modal-nav-close-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      type: "checkbox",
      id: "projects-modal",
      checked: _ctx.projectsModalVisible,
      class: "modal-toggle"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: "modal sm:modal-bottom md:modal-middle",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showProjectsModal(0, false)))
    }, [
      _createElementVNode("div", {
        class: "modal-box flex flex-col",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_cv_section_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_the_projects_modal_content, {
                data: _ctx.data[_ctx.projectsModalIndex],
                goNext: 
            () =>
              _ctx.showProjectsModal(
                _ctx.nextItemIndex(_ctx.data.length, _ctx.projectsModalIndex),
                true
              )
          
              }, null, 8, ["data", "goNext"]),
              _createVNode(_component_modal_tech_stack, {
                data: _ctx.data[_ctx.projectsModalIndex].techStack
              }, null, 8, ["data"]),
              _createVNode(_component_modal_pager, {
                iconName: "bul",
                totalItems: _ctx.data.length,
                currentItem: _ctx.projectsModalIndex,
                goToItemByIndex: (itemIndex) => _ctx.showProjectsModal(itemIndex, true)
              }, null, 8, ["totalItems", "currentItem", "goToItemByIndex"]),
              _createVNode(_component_modal_nav_close_btn, {
                prev: _ctx.prevItemIndex(_ctx.data.length, _ctx.projectsModalIndex),
                next: _ctx.nextItemIndex(_ctx.data.length, _ctx.projectsModalIndex),
                goToItemByIndex: (itemIndex) => _ctx.showProjectsModal(itemIndex, true),
                closeModal: () => _ctx.showProjectsModal(0, false)
              }, null, 8, ["prev", "next", "goToItemByIndex", "closeModal"])
            ]))
      ])
    ])
  ], 64))
}