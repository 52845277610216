
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'locale-changer',
  data: () => {
    return {
      langs: [
        {
          key: 'ru',
          flag: '🇷🇺',
        },
        {
          key: 'en',
          flag: '🇬🇧',
        },
      ],
    }
  },
})
