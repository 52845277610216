import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cv_section = _resolveComponent("cv-section")!

  return (_openBlock(), _createBlock(_component_cv_section, {
    s_name: "educations",
    isLoading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (ed) => {
        return (_openBlock(), _createElementBlock("div", {
          key: ed.key,
          class: "section__body text-justify"
        }, _toDisplayString(ed.description[_ctx.$i18n.locale]), 1))
      }), 128))
    ]),
    _: 1
  }, 8, ["isLoading"]))
}