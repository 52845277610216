
import { defineComponent, PropType, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { IProject } from '@/store/cvInterfaces'
import TheCertificationsModalContent from '@/components/cv/modal/TheCertificationsModalContent.vue'
import ModalTechStack from '@/components/cv/modal/ModalTechStack.vue'
import ModalMixin from '@/components/cv/modal/ModalMixin.vue'
import ModalPager from '@/components/cv/modal/ModalPager.vue'
import ModalNavCloseBtn from '@/components/cv/modal/ModalNavCloseBtn.vue'
import CvSectionLoader from '@/components/cv/CvSectionLoader.vue'

export default defineComponent({
  mixins: [ModalMixin],
  components: {
    TheCertificationsModalContent,
    ModalTechStack,
    ModalPager,
    ModalNavCloseBtn,
    CvSectionLoader
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array as PropType<IProject[]>,
      required: true,
    },
  },
  setup() {
    const certModalVisible = ref(false)
    const certModalIndex = ref(0)
    const router = useRouter()

    const route = useRoute()
    const routeHash = route.hash
    if (routeHash.includes('-')) {
      const [key, val] = routeHash.split('-')
      if (key === '#cert') {
        certModalVisible.value = true
        certModalIndex.value = parseInt(val || '0')
        console.log('))))))))))', certModalIndex.value, '))');
        router.replace({ path: '/' })
      }
    }

    const showCertModal = (index: number, flag = true) => {
      certModalVisible.value = flag
      certModalIndex.value = index
    }

    watch(
      () => route.hash,
      async (routeHash) => {
        const [key, val] = routeHash.split('-')
        if (key === '#cert') {
          certModalVisible.value = true
          certModalIndex.value = parseInt(val)
          router.replace({ path: '/' })
        }
      }
    )

    return {
      showCertModal,
      certModalVisible,
      certModalIndex,
    }
  },
})
