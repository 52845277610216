
// Main Info component
import { defineComponent, PropType } from 'vue'
import { IiAm } from '@/store/cvInterfaces'
import TheLocaleSelector from '@/components/TheLocaleSelector.vue'

export default defineComponent({
  components: {
    TheLocaleSelector,
  },
  props: {
    data: {
      type: Object as PropType<IiAm>,
      required: true,
    },
  },
})
