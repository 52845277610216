
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    prev: {
      type: Number,
      required: true,
    },
    next: {
      type: Number,
      required: true,
    },
    goToItemByIndex: {
      type: Function as PropType<(newInd: number) => void>,
      required: true,
    },
    closeModal: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
})
