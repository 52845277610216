import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section__body" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "certificate__title text-sm font-semibold" }
const _hoisted_4 = { class: "certificate__year text-sm font-light" }
const _hoisted_5 = { class: "flex certificate__description w-full text-left self-start" }
const _hoisted_6 = { class: "w-full underline" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cv_section = _resolveComponent("cv-section")!

  return (_openBlock(), _createBlock(_component_cv_section, {
    s_name: "certifications",
    isLoading: _ctx.isLoading
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (cert, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "certificate flex flex-col pb-3",
            key: cert.key
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(cert.title[_ctx.$i18n.locale]), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(cert.to && cert.to.getFullYear()), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, [
                _createElementVNode("a", {
                  href: `#cert-${index}`
                }, _toDisplayString(cert.description[_ctx.$i18n.locale]), 9, _hoisted_7)
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["isLoading"]))
}