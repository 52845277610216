import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "company_position" }
const _hoisted_2 = { class: "company_period text-sm f" }
const _hoisted_3 = { class: "company_company text-sm font-bold" }
const _hoisted_4 = { class: "company_responsibilities ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cv_section = _resolveComponent("cv-section")!

  return (_openBlock(), _createBlock(_component_cv_section, { s_name: "experience" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (company) => {
        return (_openBlock(), _createElementBlock("div", {
          key: company.company['en'],
          class: "company m-4"
        }, [
          _createElementVNode("div", _hoisted_1, _toDisplayString(company.position[_ctx.$i18n.locale]), 1),
          _createElementVNode("div", _hoisted_2, _toDisplayString(company.from[_ctx.$i18n.locale]) + " — " + _toDisplayString(company.to[_ctx.$i18n.locale]), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(company.company[_ctx.$i18n.locale]), 1),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(company.responsibilities, (resp) => {
              return (_openBlock(), _createElementBlock("li", {
                key: resp.en,
                class: "text-justify"
              }, _toDisplayString(resp[_ctx.$i18n.locale]), 1))
            }), 128))
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }))
}