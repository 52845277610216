import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "techStack flex flex-wrap justify-evenly m-2" }
const _hoisted_2 = ["alt", "title", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (tt) => {
      return (_openBlock(), _createElementBlock("img", {
        key: tt,
        alt: tt,
        title: tt,
        class: "w-8 p-1",
        src: _ctx.getTechImgUrl(tt)
      }, null, 8, _hoisted_2))
    }), 128))
  ]))
}