import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section__body flex justify-between" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "text-left" }
const _hoisted_4 = { class: "text-left text-sm" }
const _hoisted_5 = { class: "text-left text-sm" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href", "v-text"]
const _hoisted_8 = { class: "grow" }
const _hoisted_9 = { class: "text-right" }
const _hoisted_10 = { class: "text-right text-sm" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "text-right text-sm" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "text-right text-sm" }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "text-right text-sm" }
const _hoisted_17 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cv_section = _resolveComponent("cv-section")!

  return (_openBlock(), _createBlock(_component_cv_section, { s_name: "contacts" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.data.city[_ctx.$i18n.locale]), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.data.address[_ctx.$i18n.locale]), 1),
          _createElementVNode("p", _hoisted_5, [
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.data.home.url
            }, _toDisplayString(_ctx.data.home.title), 9, _hoisted_6)
          ]),
          _createElementVNode("a", {
            target: "_blank",
            href: _ctx.data.telegram.url,
            "v-text": _ctx.data.telegram.title
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.data.phone.title), 1),
          _createElementVNode("p", _hoisted_10, [
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.data.email.url
            }, _toDisplayString(_ctx.data.email.title), 9, _hoisted_11)
          ]),
          _createElementVNode("p", _hoisted_12, [
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.data.telegram.url
            }, _toDisplayString(_ctx.data.telegram.title), 9, _hoisted_13)
          ]),
          _createElementVNode("p", _hoisted_14, [
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.data.github.url
            }, _toDisplayString(_ctx.data.github.title), 9, _hoisted_15)
          ]),
          _createElementVNode("p", _hoisted_16, [
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.data.linkedIn.url
            }, _toDisplayString(_ctx.data.linkedIn.title), 9, _hoisted_17)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}