import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2534b833"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "locale-changer" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langs, (lang) => {
        return (_openBlock(), _createElementBlock("option", {
          key: lang.key,
          value: lang.key
        }, _toDisplayString(lang.flag) + " " + _toDisplayString(_ctx.$t(`localSwitcher.${lang.key.toUpperCase()}`)), 9, _hoisted_2))
      }), 128))
    ], 512), [
      [_vModelSelect, _ctx.$i18n.locale]
    ])
  ]))
}