
import { defineComponent, computed } from 'vue'
import { IiAm, IProject } from '@/store/cvInterfaces'
import { useProjectsStore } from '@/store/projectsStore'
import { useCVStore } from '@/store/cvStore'
import TheMainInfo from '@/components/cv/TheMainInfo.vue'
import TheEducations from '@/components/cv/TheEducations.vue'
import TheLanguages from '@/components/cv/TheLanguages.vue'
import TheCertifications from '@/components/cv/TheCertifications.vue'
import TheProjects from '@/components/cv/TheProjects.vue'
import TheCertificationsModal from '@/components/cv/modal/TheCertificationsModal.vue'
import TheProjectsModal from '@/components/cv/modal/TheProjectsModal.vue'
import TheContacts from '@/components/cv/TheContacts.vue'
import TheSkills from '@/components/cv/TheSkills.vue'
import TheSummary from '@/components/cv/TheSummary.vue'
import TheExperience from '@/components/cv/TheExperience.vue'

export default defineComponent({
  components: {
    TheSummary,
    TheEducations,
    TheLanguages,
    TheCertifications,
    TheSkills,
    TheExperience,
    TheContacts,
    TheMainInfo,
    TheProjects,
    TheCertificationsModal,
    TheProjectsModal,
  },
  setup() {
    const cvStore = useCVStore()
    const projectsStore = useProjectsStore()
    cvStore.init()
    projectsStore.init()
    const educations = computed(() => projectsStore.educations as IProject[])
    const projects = computed(() => projectsStore.projects as IProject[])
    const projectsIsLoading = computed(() => projectsStore.isLoading)
    const certificatesIsLoading = computed(() => cvStore.isLoading)
    const certificates = computed(() => cvStore.iAm.certificates as IProject[])
    const iAm = computed(() => cvStore.iAm as IiAm)
    return {
      iAm,
      educations,
      projects,
      certificates,
      projectsIsLoading,
      certificatesIsLoading
    }
  },
})
