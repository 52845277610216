
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    iconName: {
      type: String,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    currentItem: {
      type: Number,
      required: true,
    },
    goToItemByIndex: {
      type: Function as PropType<(newInd: number) => void>,
      required: true,
    },
  },
})
