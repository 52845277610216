import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cv_section_loader = _resolveComponent("cv-section-loader")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([_ctx.s_name, "p-3 block-section"])
  }, [
    _createVNode(_Transition, {
      name: "fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        (!_ctx.hideHeader)
          ? (_openBlock(), _createElementBlock("h2", {
              key: 0,
              class: _normalizeClass(`block-title ${_ctx.s_name}-head`)
            }, _toDisplayString(_ctx.$t(`CV.${_ctx.s_name}`)), 3))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      name: "fade2",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_cv_section_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(`block-body ${_ctx.s_name}-body`)
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 2))
      ]),
      _: 3
    })
  ], 2))
}