
import { defineComponent, ref } from 'vue'
import CvSection from './CvSection.vue' // @ is an alias to /src

export default defineComponent({
  components: {
    CvSection,
  },
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  setup(props) {
    const hoveredGroup = ref('')
    const onMouseOver = (gr: string) => {
      hoveredGroup.value = gr
    }
    const onMouseOut = () => {
      hoveredGroup.value = ''
    }

    return {
      onMouseOver,
      onMouseOut,
      hoveredGroup,
      skills: props.data.map((ss) => (ss as string[]).join(' ')),
    }
  },
})
