import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "btn-group w-full self-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goToItemByIndex(_ctx.prev)), ["stop"])),
      class: "btn"
    }, "❮"),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.closeModal()), ["stop"])),
      class: "btn grow"
    }, _toDisplayString(_ctx.$t('common.close')), 1),
    _createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.goToItemByIndex(_ctx.next)), ["stop"])),
      class: "btn"
    }, "❯")
  ]))
}