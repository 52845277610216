import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box-content w-full flex grow flex-col md:flex-row" }
const _hoisted_2 = { class: "md:w-1/3" }
const _hoisted_3 = ["alt", "src"]
const _hoisted_4 = { class: "md:w-2/3 p-8" }
const _hoisted_5 = { class: "uppercase tracking-wide text-sm text-indigo-700 font-semibold" }
const _hoisted_6 = { class: "block mt-1 text-lg leading-tight font-medium text-black hover:underline" }
const _hoisted_7 = { class: "mt-2 p-2 text-gray-500 overflow-auto h-56 text-justify" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goNext()), ["stop"])),
        class: "project-screen cursor-pointer",
        alt: `@/assets/img/screens/${_ctx.data.img}`,
        src: require(`@/assets/img/screens/${_ctx.data.img}`)
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data.title[_ctx.$i18n.locale]), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.data.from.getFullYear()) + " - " + _toDisplayString(_ctx.data.to?.getFullYear()), 1),
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.data.description[_ctx.$i18n.locale]), 1)
    ])
  ]))
}