import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77036742"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "skills text-justify flex flex-wrap" }
const _hoisted_2 = ["textContent", "onMouseover"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cv_section = _resolveComponent("cv-section")!

  return (_openBlock(), _createBlock(_component_cv_section, { s_name: "skills" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skills, (skillSet, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(skillSet.split(' '), (skill) => {
              return (_openBlock(), _createElementBlock("span", {
                class: _normalizeClass(["skill-tag bg-light-900 px-2", {
            'tag-gr__selected': i.toString() === _ctx.hoveredGroup,
            'tag-gr': i % 2,
          }]),
                key: skill,
                textContent: _toDisplayString(`${skill} `),
                onMouseover: ($event: any) => (_ctx.onMouseOver(`${i}`)),
                onMouseout: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseOut && _ctx.onMouseOut(...args)))
              }, null, 42, _hoisted_2))
            }), 128))
          ], 64))
        }), 128))
      ])
    ]),
    _: 1
  }))
}