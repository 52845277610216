import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da822502"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-around my-10" }
const _hoisted_2 = { class: "cursor-pointer" }
const _hoisted_3 = ["onClick", "src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalItems, (index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            onClick: _withModifiers(($event: any) => (_ctx.goToItemByIndex(index - 1)), ["stop"]),
            src: 
            require(`@/assets/img/icons/${_ctx.iconName}${
              _ctx.currentItem === index - 1 ? '-selected' : ''
            }.svg`)
          ,
            width: "20",
            height: "20",
            alt: `certificate item ${index}`
          }, null, 8, _hoisted_3)
        ])
      ]))
    }), 128))
  ]))
}