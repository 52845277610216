import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b2c44dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["checked"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cv_section_loader = _resolveComponent("cv-section-loader")!
  const _component_the_certifications_modal_content = _resolveComponent("the-certifications-modal-content")!
  const _component_modal_tech_stack = _resolveComponent("modal-tech-stack")!
  const _component_modal_pager = _resolveComponent("modal-pager")!
  const _component_modal_nav_close_btn = _resolveComponent("modal-nav-close-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      type: "checkbox",
      id: "cert-modal",
      checked: _ctx.certModalVisible,
      class: "modal-toggle"
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: "modal modal-bottom sm:modal-middle min-w-min cursor-pointer",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCertModal(0, false)))
    }, [
      _createElementVNode("div", {
        class: "modal-box overflow-hidden h-full flex flex-col min-h-min",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_cv_section_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_the_certifications_modal_content, {
                data: _ctx.data[_ctx.certModalIndex],
                goNext: 
            () => _ctx.showCertModal(_ctx.nextItemIndex(_ctx.data.length, _ctx.certModalIndex), true)
          
              }, null, 8, ["data", "goNext"]),
              _createVNode(_component_modal_tech_stack, {
                data: _ctx.data[_ctx.certModalIndex].techStack
              }, null, 8, ["data"]),
              _createVNode(_component_modal_pager, {
                iconName: "certificate",
                totalItems: _ctx.data.length,
                currentItem: _ctx.certModalIndex,
                goToItemByIndex: (itemIndex) => {
            _ctx.showCertModal(itemIndex, true)
          }
              }, null, 8, ["totalItems", "currentItem", "goToItemByIndex"]),
              _createVNode(_component_modal_nav_close_btn, {
                prev: _ctx.prevItemIndex(_ctx.data.length, _ctx.certModalIndex),
                next: _ctx.nextItemIndex(_ctx.data.length, _ctx.certModalIndex),
                closeModal: () => _ctx.showCertModal(0, false),
                goToItemByIndex: (itemIndex) => _ctx.showCertModal(itemIndex, true)
              }, null, 8, ["prev", "next", "closeModal", "goToItemByIndex"])
            ]))
      ])
    ])
  ], 64))
}