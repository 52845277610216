import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section__body ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cv_section = _resolveComponent("cv-section")!

  return (_openBlock(), _createBlock(_component_cv_section, { s_name: "languages" }, {
    default: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (lang) => {
          return (_openBlock(), _createElementBlock("li", {
            key: lang.en
          }, _toDisplayString(lang[_ctx.$i18n.locale]), 1))
        }), 128))
      ])
    ]),
    _: 1
  }))
}