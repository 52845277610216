import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15392801"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "md:container md:mx-auto main-container md:m-4" }
const _hoisted_2 = { class: "about grid gap-5 lg:grid-cols-3" }
const _hoisted_3 = { class: "space-y-5" }
const _hoisted_4 = { class: "space-y-5 lg:col-span-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_main_info = _resolveComponent("the-main-info")!
  const _component_the_contacts = _resolveComponent("the-contacts")!
  const _component_the_educations = _resolveComponent("the-educations")!
  const _component_the_languages = _resolveComponent("the-languages")!
  const _component_the_certifications = _resolveComponent("the-certifications")!
  const _component_the_skills = _resolveComponent("the-skills")!
  const _component_the_summary = _resolveComponent("the-summary")!
  const _component_the_experience = _resolveComponent("the-experience")!
  const _component_the_projects = _resolveComponent("the-projects")!
  const _component_the_certifications_modal = _resolveComponent("the-certifications-modal")!
  const _component_the_projects_modal = _resolveComponent("the-projects-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_the_main_info, { data: _ctx.iAm }, null, 8, ["data"]),
          _createVNode(_component_the_contacts, {
            data: _ctx.iAm.contacts
          }, null, 8, ["data"]),
          _createVNode(_component_the_educations, {
            data: _ctx.educations,
            isLoading: _ctx.projectsIsLoading
          }, null, 8, ["data", "isLoading"]),
          _createVNode(_component_the_languages, {
            data: _ctx.iAm.languages
          }, null, 8, ["data"]),
          _createVNode(_component_the_certifications, {
            data: _ctx.certificates,
            isLoading: _ctx.certificatesIsLoading
          }, null, 8, ["data", "isLoading"]),
          _createVNode(_component_the_skills, {
            data: _ctx.iAm.skills
          }, null, 8, ["data"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_the_summary, {
            data: _ctx.iAm.summary
          }, null, 8, ["data"]),
          _createVNode(_component_the_experience, {
            data: _ctx.iAm.experience,
            isLoading: _ctx.certificatesIsLoading
          }, null, 8, ["data", "isLoading"]),
          _createVNode(_component_the_projects, { data: _ctx.projects }, null, 8, ["data"])
        ])
      ])
    ]),
    _createVNode(_component_the_certifications_modal, {
      data: _ctx.certificates,
      isLoading: _ctx.certificatesIsLoading
    }, null, 8, ["data", "isLoading"]),
    _createVNode(_component_the_projects_modal, {
      data: _ctx.projects,
      isLoading: _ctx.projectsIsLoading
    }, null, 8, ["data", "isLoading"])
  ], 64))
}