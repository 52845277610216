
import { defineComponent } from 'vue'
import CvSectionLoader from '@/components/cv/CvSectionLoader.vue'


export default defineComponent({
  name: 'CvSection',
  components: {
    CvSectionLoader
  },
  props: {
    hideHeader: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    s_name: {
      type: String,
      required: false,
      default: ''
    },
  },
})
