
// Certification component
import { defineComponent, PropType } from 'vue'
import { IProject } from '@/store/cvInterfaces'
import CvSection from './CvSection.vue'

export default defineComponent({
  components: {
    CvSection,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array as PropType<IProject[]>,
      required: true,
    },
  },
})
