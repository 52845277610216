
// Experiences component
import { defineComponent, PropType } from 'vue'
import CvSection from './CvSection.vue'
import { IExperience } from '@/store/cvInterfaces'

export default defineComponent({
  components: {
    CvSection,
  },
  props: {
    data: {
      type: Array as PropType<IExperience[]>,
      required: true,
    },
  },
})
