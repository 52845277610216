import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full p-1" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "text-sm" }
const _hoisted_4 = { class: "underline" }
const _hoisted_5 = { class: "text-base" }
const _hoisted_6 = { class: "grow" }
const _hoisted_7 = { click: "showCertMobile(nextCertIndex(i-1), true)" }
const _hoisted_8 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        href: _ctx.data.url,
        target: "_blank"
      }, [
        _createElementVNode("h3", _hoisted_3, [
          _createTextVNode(" 🔗 "),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.data.description[_ctx.$i18n.locale]), 1)
        ])
      ], 8, _hoisted_2),
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.data.title[_ctx.$i18n.locale]), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goNext()), ["stop"])),
          alt: _ctx.data.title[_ctx.$i18n.locale],
          src: require(`@/assets/img/cert/${_ctx.data.img}`),
          class: "w-full cert-img cursor-pointer"
        }, null, 8, _hoisted_8)
      ])
    ])
  ], 64))
}