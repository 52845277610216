
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    data: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  methods: {
    getTechImgUrl: (tt: string) => {
      try {
        return require(`@/assets/img/icons/${tt}.svg`)
      } catch {
        return require(`@/assets/img/icons/amazons3.svg`)
      }
    },
  },
})
